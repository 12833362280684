import { FC } from 'react'
import { useAppSelector } from '../../../../store/hooks'
import { GeneratorInfoFrame } from '../../../ui'
import { selectDictionariesMaps } from '../../../../store/dictionaries'
import { AccountTopBlock, RepaymentsBlock, DividedBlock, DocumentsBlock } from '../'
import {
    commonInfoConfig,
    restorePasswordConfig,
    changeEmailConfig,
    createGOConfig,
    approveGOConfig,
    activateGOConfig,
    openAccountConfig,
    accountServiceConfig,
    closeAccountConfig,
    openCertificateConfig,
    changeCertificateOwnerConfig,
    repaymentConfig,
    blockCertificateConfig,
    applicationPaymentConfig,
    autoPaymentConfig,
    AGRealisationConfig,
    blockContractConfig,
    AGReservationConfig,
    AGCancelReservationConfig,
    OthersAGConfig,
    AGTransferConfig,
    OwnConsumingAGConfig,
    AGRemoveMark,
    OwnNeedsConfig,
    repaymentConfigAG,
    switchAGConfig,
    fixationConfig,
    deregisterConfig,
    sendSPtoETPConfig,
    blockAGConfig,
    changeAccountNumber,
    AGToThirdConfig,
    changeContractNumber,
} from './configuration'
import { ConsumersTable } from '../ConsumersTable'
import { CertificateConsumersTable } from '../CertificateConsumersTable'
import { SeparateConsumersTable } from '../SeparateConsumersTable'
import RepaymentsBlockAG from '../RepaymentsBlockAG/RepaymentsBlock'

type PaginationType = {
    page?: number
    pageSize?: number
}

interface IContentBlock {
    eventData: any
    eventDetails: any
    getCustomData: (data: PaginationType) => void
}

const ContentBlock: FC<IContentBlock> = ({ eventData, eventDetails, getCustomData }) => {
    const { eventId } = eventData
    const dictionaries = useAppSelector(selectDictionariesMaps)

    const chooseConfig = () => {
        switch (eventId) {
            case 1:
                return (
                    <>
                        <GeneratorInfoFrame config={commonInfoConfig(eventDetails)} />
                        <GeneratorInfoFrame config={restorePasswordConfig(eventDetails)} />
                    </>
                )
            case 2:
                return <GeneratorInfoFrame config={commonInfoConfig(eventDetails)} />
            case 3:
                return (
                    <>
                        <GeneratorInfoFrame config={commonInfoConfig(eventDetails)} />
                        <GeneratorInfoFrame config={changeEmailConfig(eventDetails)} />
                    </>
                )
            case 65:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame config={createGOConfig(eventDetails, dictionaries)} />
                    </>
                )
            case 35:
                return (
                    <>
                        <GeneratorInfoFrame config={commonInfoConfig(eventDetails)} />
                        <GeneratorInfoFrame config={approveGOConfig(eventDetails)} />
                    </>
                )
            case 37:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame config={activateGOConfig(eventDetails)} />
                    </>
                )
            case 10:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame config={openAccountConfig(eventDetails)} />
                    </>
                )
            case 50:
                return (
                    <>
                        <GeneratorInfoFrame config={commonInfoConfig(eventDetails)} />
                        <GeneratorInfoFrame config={accountServiceConfig(eventDetails)} />
                    </>
                )
            case 14:
                return (
                    <>
                        <GeneratorInfoFrame config={commonInfoConfig(eventDetails)} />
                        <GeneratorInfoFrame config={closeAccountConfig(eventDetails)} />
                    </>
                )
            case 17:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame
                            config={changeAccountNumber(eventDetails, dictionaries)}
                        />
                    </>
                )
            case 15:
            case 19:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame
                            config={openCertificateConfig(eventDetails, dictionaries)}
                        />
                    </>
                )
            case 29:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame
                            config={changeCertificateOwnerConfig(eventDetails, dictionaries)}
                        />
                    </>
                )
            case 30:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame
                            config={repaymentConfig(eventDetails)}
                            bottomChildren={
                                <RepaymentsBlock
                                    getCustomData={getCustomData}
                                    eventDetails={eventDetails}
                                />
                            }
                        />
                    </>
                )
            case 20:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <DividedBlock
                            dictionaries={dictionaries}
                            getCustomData={getCustomData}
                            eventDetails={eventDetails}
                        />
                    </>
                )
            case 21:
                return (
                    <>
                        <GeneratorInfoFrame config={commonInfoConfig(eventDetails)} />
                        <GeneratorInfoFrame
                            config={blockCertificateConfig(eventDetails, dictionaries)}
                        />
                    </>
                )
            case 23:
                return (
                    <>
                        <GeneratorInfoFrame config={commonInfoConfig(eventDetails)} />
                        <GeneratorInfoFrame
                            config={blockCertificateConfig(eventDetails, dictionaries, true)}
                        />
                    </>
                )
            case 70:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame config={applicationPaymentConfig(eventDetails)} />
                    </>
                )
            case 56:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame config={autoPaymentConfig(eventDetails)} />
                    </>
                )
            case 74:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame
                            config={AGRealisationConfig(eventDetails)}
                            bottomChildren={
                                <ConsumersTable consumers={eventDetails?.payload?.consumers} />
                            }
                        />
                    </>
                )
            case 22:
            case 24:
                return (
                    <>
                        <GeneratorInfoFrame config={commonInfoConfig(eventDetails)} />
                        <GeneratorInfoFrame
                            config={blockContractConfig(eventDetails, dictionaries)}
                        />
                    </>
                )
            case 58:
            case 72:
            case 83:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame config={AGReservationConfig(eventDetails)} />
                    </>
                )
            case 59:
            case 73:
            case 84:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} />
                        <GeneratorInfoFrame config={AGCancelReservationConfig(eventDetails)} />
                    </>
                )
            case 79:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame config={OthersAGConfig(eventDetails, dictionaries)} />
                    </>
                )
            case 80:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame
                            config={AGTransferConfig(eventDetails)}
                            bottomChildren={<SeparateConsumersTable eventDetails={eventDetails} />}
                        />
                    </>
                )
            case 66:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame config={OwnConsumingAGConfig(eventDetails)} />
                    </>
                )
            case 67:
                return (
                    <>
                        <>
                            <AccountTopBlock eventDetails={eventDetails} />
                            <GeneratorInfoFrame
                                config={repaymentConfigAG(eventDetails)}
                                bottomChildren={
                                    <RepaymentsBlockAG
                                        getCustomData={getCustomData}
                                        eventDetails={eventDetails}
                                    />
                                }
                            />
                        </>
                    </>
                )
            case 81:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame config={AGRemoveMark(eventDetails)} />
                    </>
                )
            case 82:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame config={OwnNeedsConfig(eventDetails)} />
                    </>
                )
            case 18:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame config={switchAGConfig(eventDetails, dictionaries)} />
                    </>
                )
            case 45:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame config={fixationConfig(eventDetails)} />
                    </>
                )
            case 41:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame config={deregisterConfig(eventDetails)} />
                    </>
                )
            case 32:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame
                            config={sendSPtoETPConfig(eventDetails, dictionaries)}
                        />
                    </>
                )
            case 46:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame
                            config={blockAGConfig(eventDetails)}
                            bottomChildren={
                                <DocumentsBlock documents={eventDetails?.payload?.documents} />
                            }
                        />
                    </>
                )
            case 85:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame
                            config={AGToThirdConfig(eventDetails)}
                            bottomChildren={
                                <CertificateConsumersTable
                                    eventDetails={eventDetails}
                                />
                            }
                        />
                    </>
                )
            case 88:
                return (
                    <>
                        <AccountTopBlock eventDetails={eventDetails} noSign />
                        <GeneratorInfoFrame
                            config={changeContractNumber(eventDetails, dictionaries)}
                        />
                    </>
                )
            default:
                return <></>
        }
    }

    const config = chooseConfig()

    return config
}

export default ContentBlock
