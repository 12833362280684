import { useLocation, useParams } from 'react-router-dom'
import { Tabs } from 'antd'
import { BookSaved } from '../../assets/icons'
import { getUserName } from '../../utils/formatter'
import PageTitle from '../ui/PageElements/PageTitle/PageTitle'
import PageContainer from '../ui/PageElements/PageContainer/PageContainer'
import { BackArrow, Box, IconButtonSmall, LoadingContainer, LockBadge } from '../ui'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import useMount from '../hooks/useMount'
import { GeneralInfo, PaymentAccount, PersonalAccount } from '../Profile/components'
import { getTabs } from './helpers'

import style from './style.m.less'
import { useEffect, useState } from 'react'
import { isUserAdmin } from '../../constants/approval'
import { EUserCardTabs } from './types'
import request from '../../services/request'
import { HistoryModal } from '../QuestionnaireApprovalItem/components'

const { TabPane } = Tabs

const UserCard = () => {
    const { id } = useParams()
    const { state } = useLocation()

    const [currentTab, setCurrentTab] = useState<EUserCardTabs>(EUserCardTabs.PROFILE)

    const { fetch, result, errorMessage, isLoading } = useRequest({
        link: `/api/users/v1/profile-admin?uid=${id}`,
        method: RequestTypes.get,
    })

    const { fetch: fetchBalances, result: balances } = useRequest({
        link: `/api/billing/v1/account-balances`,
        method: RequestTypes.get,
    })

    useMount(() => {
        fetch({})
    })

    useEffect(() => {
        if (result && result?.profile_status !== 0 && !isUserAdmin(result?.user_role)) {
            fetchBalances({
                getParams: {
                    uid: id,
                },
            })
        }
    }, [result])

    const updateUser = () => {
        fetch({})
    }

    const onTabClick = (tab: string) => setCurrentTab(tab as EUserCardTabs)

    const tabs = getTabs(result?.user_role)

    const tabsContent: Record<EUserCardTabs, JSX.Element> = {
        [EUserCardTabs.PROFILE]: (
            <GeneralInfo
                user={{
                    data: result,
                    isLoading,
                    error: errorMessage,
                    actualData: result,
                }}
                updateUser={updateUser}
            />
        ),
        [EUserCardTabs.PAYMENT]: <PaymentAccount userId={id} userBalances={balances} />,
        [EUserCardTabs.PERSONAL]: <PersonalAccount userId={id} accountId={state?.accountId} />,
        // 'ЗИ пользователя': <>ЗИ пользователя</>,
        // 'ГО пользователя': <UserGOs />,
    }

    const defaultActiveKey = state?.currentTab
        ? state?.currentTab
        : state?.accountId
        ? EUserCardTabs.PERSONAL
        : EUserCardTabs.PROFILE

    const [isHistoryVisible, setIsHistoryVisible] = useState(false)
    const openHistory = () => setIsHistoryVisible(true)
    const closeHistory = () => setIsHistoryVisible(false)
    const [history, setHistory] = useState()

    useEffect(() => {
        const loadHistory = async () => {
            const res = await request.get(`/api/users/v1/profiles-events?uid=${id}`)
            setHistory(res.data)
        }
        loadHistory()
    }, [id])

    return (
        <LoadingContainer isLoading={isLoading}>
            <PageContainer>
                <div className={style.header}>
                    <PageTitle
                        title={
                            <div className={style.title}>
                                <BackArrow />
                                {getUserName(result)}
                                {result?.is_blocked && <LockBadge />}
                            </div>
                        }
                    />
                    <IconButtonSmall icon={<BookSaved />} onClick={openHistory}>
                        История изменений
                    </IconButtonSmall>
                </div>
                <Tabs
                    defaultActiveKey={defaultActiveKey}
                    onTabClick={onTabClick}
                    className={style.tabs}
                >
                    {result &&
                        tabs?.map((tab) => {
                            return (
                                <TabPane key={tab} tab={tab}>
                                    {tabsContent[tab]}
                                </TabPane>
                            )
                        })}
                </Tabs>
                <HistoryModal
                    isModalVisible={isHistoryVisible}
                    onClose={closeHistory}
                    history={history}
                    uid={id}
                    setIsModalVisible={setIsHistoryVisible}
                />
            </PageContainer>
        </LoadingContainer>
    )
}

export default UserCard
