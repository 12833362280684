import { FC } from 'react'
import { useSelector } from 'react-redux'
import style from './style.m.less'
import { currencyFormat } from '../../../helpers'
import IAppState from '../../../../store/states'
import { EUserRole } from '../../../../constants/approval'

interface CertificateConsumersTableProps {
    eventDetails: any
}

export const CertificateConsumersTable: FC<CertificateConsumersTableProps> = ({ eventDetails }) => {
    const userInfo = useSelector((store: IAppState) => store.user)
    const isAdminOrOperator =
        userInfo.data.user_role === EUserRole.SuperAdmin ||
        userInfo.data.user_role === EUserRole.Administrator ||
        userInfo.data.user_role === EUserRole.Operator
    const showAllRepayments = isAdminOrOperator || userInfo.data.uid === eventDetails.uid_creator

    const getList = () => {
        if( showAllRepayments )
            return eventDetails.payload.consumers
        else {   
            const data = []
            for (const consumer of eventDetails.payload.consumers) {
                if( consumer.user_id === userInfo.data.uid )
                    data.push(consumer)
            }
            return data
        }    
    }
    const getTotal = () => {
        if( showAllRepayments )
            return eventDetails.payload.consumers.length
        else {   
            let data = 0
            for (const consumer of eventDetails.payload.consumers) {
                if( consumer.user_id === userInfo.data.uid )
                    data++;
            }
            return data
        }    
    }
    const list = getList()
    const total = getTotal()

    return (
        <div className={style.wrapper}>
            <div className={style.table}>
                <div className={style.header}>
                    {`Потребители СП (${total})`}
                </div>
                <div className={style.row}>
                    <div className={style.column} style={{ fontWeight: '600' }}>
                        Потребитель
                    </div>
                    <div className={style.column} style={{ fontWeight: '600' }}>
                        Объем, кВт*ч
                    </div>
                </div>
                {list?.map((item: any, index: number) => (
                    <div className={style.row}>
                        <div className={style.column}>{item.user_name}</div>
                        <div className={style.column}>{currencyFormat(item.volume)}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
