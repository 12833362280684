import { FC, useEffect, useState } from 'react'
import { Divider, Input, Radio, Form, DatePicker, message } from 'antd'
import {
    CommonBlock,
    TagSelector,
    SelectedTsoGp2,
    DocumentsBlock,
    SelectedGO,
    SuccessMessage,
    QualificationCategoryModal,
} from '../../components'
import {
    Alert,
    DropdownSelector2,
    PrimaryButton,
    PrimaryIconButton,
    CenteredSpin,
} from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { AddEditGP, AddEditTSO, SearchGP, SearchTSO } from '../../../GOActivation/components'
import { ITSO } from '../../../GOActivation/components/AddEditTSO/AddEditTSO'
import { useNavigate, useParams } from 'react-router-dom'
import { selectDictionariesOptions } from '../../../../store/dictionaries'
import { useAppSelector } from '../../../../store/hooks'
import { IGP } from '../../../GOActivation/components/AddEditGP/AddEditGP'
import { IFile } from '../../components/DocumentsList/DocumentsList'
import request from '../../../../services/request'
import moment from 'moment'
import useRequest from '../../../hooks/useRequest'
import { getGO } from '../../config'

const { TextArea } = Input

const MESSAGE = 'Поле обязательно для заполнения'

interface IGeneratorLayoutAdmin {
    type: 'generator' | 'create' | 'profile'
}

const GeneratorLayoutAdmin: FC<IGeneratorLayoutAdmin> = ({ type }) => {
    const { t } = useTranslation()
    const { fetch, isLoading: loading } = useRequest(getGO)

    const { id } = useParams<{ id?: string }>()
    const [generator, setGenerator] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (id) {
            fetch({
                getParams: {
                    generator_id: id,
                },
                onSuccess(data) {
                    const params = {
                        ...data,
                        selectedFiles: data?.documents?.map((file: any) => ({
                            name: file.file_name,
                            size: file.file_size,
                            id: file.id,
                            type: file.file_name.split('.').pop(),
                            description: file.comment,
                            hash: file.hash,
                        })),
                        power: data.power / 1000,
                        uid_go_owner: data.go_owner
                            ? {
                                  uid_go_owner: data.go_owner.uid,
                                  is_uid_go_owner_draft: data.go_owner.is_draft,
                              }
                            : undefined,
                        qualification_date: data.qualification_date
                            ? moment(data.qualification_date)
                            : undefined,
                        last_check_date: data.last_check_date
                            ? moment(data.last_check_date)
                            : undefined,
                        address_index: data.address_index || '',
                        geo_lat: !data.geo_lat && !data.geo_lon ? '' : data.geo_lat,
                        geo_lon: !data.geo_lat && !data.geo_lon ? '' : data.geo_lon,
                        comissioning_date: data.comissioning_date
                            ? moment(data.comissioning_date)
                            : undefined,
                    }
                    if (!data.fuel_ids) {
                        delete params.fuel_ids
                    }
                    setGenerator(params)
                    setIsForRetail(data.is_for_retail)
                    setGoTypeId(data.go_type_id)
                    setSelectedTso(data.tso)
                    setSelectedGp(data.gp)
                },
            })
        }
    }, [id])

    const dictionaries = useAppSelector(selectDictionariesOptions)

    const isCreate = type === 'create' ? true : undefined

    const [form] = Form.useForm()
    const [goTypeId, setGoTypeId] = useState(1)
    const [isForRetail, setIsForRetail] = useState(false)

    const [selectedTso, setSelectedTso] = useState<ITSO>()
    const [openAddEditTso, setOpenAddEditTso] = useState(false)

    const [selectedGp, setSelectedGp] = useState<IGP>()
    const [openAddEditGp, setOpenAddEditGp] = useState(false)

    const navigate = useNavigate()

    const getBack = () => {
        navigate(-1)
    }

    const handleConfirm = async () => {
        try {
            const { selectedFiles, ...values } = await form.validateFields()
            const params = {
                ...values,
                ...values.uid_go_owner,
                address_index: values.address_index ? Number(values.address_index) : undefined,
                localization_degree: values.localization_degree
                    ? Number(values.localization_degree)
                    : null,
                export_rate_of_industrial_products: values.export_rate_of_industrial_products
                    ? Number(values.export_rate_of_industrial_products)
                    : null,
                power: values.power ? Number(values.power) * 1000 : undefined,
                geo_lat: values.geo_lat ? Number(values.geo_lat) : undefined,
                geo_lon: values.geo_lon ? Number(values.geo_lon) : undefined,
            }
            if (selectedTso) {
                params.uid_tso = selectedTso.uid
                params.is_uid_tso_draft = selectedTso.isDraft
            }
            if (selectedGp) {
                params.uid_gp = selectedGp.uid
                params.is_uid_gp_draft = selectedGp.isDraft
            }
            if (selectedFiles.length) {
                params.documents = selectedFiles?.map((file: IFile) => ({
                    id: file.id,
                    comment: file.description,
                    hash: file.hash,
                }))
            }

            const url = id
                ? `/api/generators/v1/go-edit?generator_id=${id}`
                : `/api/generators/v1/go-create`

            setIsLoading(true)
            const {
                data: { generator_id },
            } = await request.post(url, params)

            if (id) {
                navigate(-1)
            } else {
                navigate(`/generator-card/${generator_id || id}`, { replace: true })
            }

            const successMessage = id
                ? `${values.name} отредактирован`
                : `${values.name} ${t('detailedCard.created')}`

            message.success({
                content: (
                    <SuccessMessage header={t('detailedCard.success')} message={successMessage} />
                ),
                icon: <></>,
            })
        } catch (error: any) {
            if (
                error &&
                error.errorFields &&
                error.errorFields[0] &&
                error.errorFields[0].name &&
                error.errorFields[0].name[0]
            ) {
                form.scrollToField(error.errorFields[0].name[0])
            }

            if (error && error.err_code === 'ERR_GO_2') {
                message.error('Указанный вами код ГТП уже используется в Системе реестра')
                form.scrollToField('point_delivery_code')
            }
        } finally {
            setIsLoading(false)
        }
    }

    const [isSearchTSOModalVisible, setIsSearchTSOModalVisible] = useState(false)
    const openSearchTSOModal = () => {
        setIsSearchTSOModalVisible(true)
    }

    const [isSearchGPModalVisible, setIsSearchGPModalVisible] = useState(false)
    const openSearchGPModal = () => {
        setIsSearchGPModalVisible(true)
    }

    const [isQualificationCategoryModalVisible, setIsQualificationCategoryModalVisible] =
        useState(false)

    if (!dictionaries || loading || isLoading) {
        return <CenteredSpin />
    }

    const {
        regionsOptions,
        qualificationCategoriesOptions,
        fullRenewableEnergyTypesOptions,
        productionModesOptions,
        categoriesGoOptions,
        fullFuelOptions,
        aidsOptions,
        ownershipsOptions,
    } = dictionaries

    const goOwnerName = id
        ? generator.go_owner?.company_name_short ||
          `ИП ${generator.go_owner?.last_name} ${generator.go_owner?.first_name} ${generator.go_owner?.middle_name}`
        : ''

    const initialForEdit = {
        qualification_category_id: qualificationCategoriesOptions[0].value,
        category_go_id: categoriesGoOptions[0].value,
        ...generator,
    }

    const initialForCreate = {
        go_type_id: goTypeId,
        is_for_retail: isForRetail,
        region_id: regionsOptions[0].value,
        renewable_energy_type_id: fullRenewableEnergyTypesOptions[0].value,
        production_mode_id: productionModesOptions[0].value,
        ownership_id: ownershipsOptions[0].value,
        selectedFiles: [],
        ...initialForEdit,
    }

    return (
        <>
            <Form
                form={form}
                initialValues={id ? initialForEdit : initialForCreate}
                className={style.form}
            >
                {id && (
                    <div className={style.editFrame}>
                        <CommonBlock
                            label="Причина редактирования"
                            select={
                                <TextArea
                                    className={style.textArea}
                                    rows={3}
                                    placeholder="Укажите причину редактирования (не более 500 символов)"
                                    maxLength={500}
                                />
                            }
                        />
                    </div>
                )}
                <div className={style.editFrame}>
                    <CommonBlock
                        required
                        label="Генерирующий объект"
                        select={
                            <Form.Item
                                name="go_type_id"
                                getValueFromEvent={(event) => {
                                    const { value } = event.target
                                    setGoTypeId(value)
                                    return value
                                }}
                            >
                                <Radio.Group className={style.radio}>
                                    <Radio value={1}>Квалифицированный ГО</Radio>
                                    <Radio value={2}>Зарегистрированный ГО</Radio>
                                </Radio.Group>
                            </Form.Item>
                        }
                    />
                    <Divider />
                    <CommonBlock
                        required
                        label={t('edit.marketEE')}
                        select={
                            <Form.Item
                                name="is_for_retail"
                                getValueFromEvent={(event) => {
                                    const { value } = event.target
                                    setIsForRetail(value)
                                    if (!value) {
                                        setSelectedTso(undefined)
                                        setSelectedGp(undefined)
                                    } else {
                                        form.setFields([
                                            { name: 'point_delivery_code', value: undefined },
                                        ])
                                    }
                                    return value
                                }}
                            >
                                <Radio.Group className={style.radio}>
                                    <Radio value={true}>{t('edit.retail')}</Radio>
                                    <Radio value={false}>{t('edit.wholesale')}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        }
                    />
                    {isForRetail && (
                        <div className={style.blockGroup}>
                            <Alert
                                message={
                                    <div>
                                        Код ГО для розничного рынка будет сгенерирован
                                        автоматически.
                                    </div>
                                }
                            />
                        </div>
                    )}
                    <Divider />
                    <CommonBlock
                        required
                        label={t('edit.name')}
                        select={
                            <Form.Item
                                style={id ? { marginBottom: '16px' } : {}}
                                name="name"
                                rules={[{ required: true, message: MESSAGE }]}
                            >
                                <Input
                                    placeholder={isCreate && t('edit.namePlaceholder')}
                                    className={style.inputLong}
                                    maxLength={1000}
                                />
                            </Form.Item>
                        }
                    />
                    {!isForRetail && (
                        <>
                            <CommonBlock
                                required
                                label="Код ГТП генерации"
                                margin
                                select={
                                    <Form.Item
                                        name="point_delivery_code"
                                        rules={[
                                            { required: true, message: MESSAGE },
                                            {
                                                len: 8,
                                                message: 'Код должен состоять из 8 символов',
                                            },
                                            {
                                                pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                                                message:
                                                    'Код должен содержать только цифры и латинские символы',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={isCreate && 'Введите код'}
                                            className={style.inputLong}
                                            minLength={8}
                                            maxLength={8}
                                        />
                                    </Form.Item>
                                }
                            />
                            <CommonBlock
                                label="Код ГТП потребления"
                                margin
                                select={
                                    <Form.Item
                                        name="point_delivery_code_consuming"
                                        rules={[
                                            { required: false, message: MESSAGE },
                                            {
                                                pattern: new RegExp(/^[a-zA-Z0-9]+$/),
                                                message:
                                                    'Код должен содержать только цифры и латинские символы',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={isCreate && 'Введите код'}
                                            className={style.inputLong}
                                            minLength={8}
                                            maxLength={8}
                                        />
                                    </Form.Item>
                                }
                            />
                        </>
                    )}
                    {!id && (
                        <>
                            <Divider />
                            <Form.Item
                                className={style.formItem}
                                name="uid_go_owner"
                                rules={[{ required: true, message: MESSAGE }]}
                            >
                                <SelectedGO initialGO={generator.go_owner} />
                            </Form.Item>
                        </>
                    )}
                    {isForRetail && (
                        <>
                            <SelectedTsoGp2
                                label="ТСО"
                                selectedTsoGp={selectedTso}
                                onEditClick={() => setOpenAddEditTso(true)}
                                onRemoveClick={() => setSelectedTso(undefined)}
                                onSearchClick={openSearchTSOModal}
                                onManualEditClick={() => setOpenAddEditTso(true)}
                            />
                            <SelectedTsoGp2
                                label="ГП"
                                selectedTsoGp={selectedGp}
                                onEditClick={() => setOpenAddEditGp(true)}
                                onRemoveClick={() => setSelectedGp(undefined)}
                                onSearchClick={openSearchGPModal}
                                onManualEditClick={() => setOpenAddEditGp(true)}
                            />
                        </>
                    )}
                    <Divider />
                    <CommonBlock
                        required
                        label={t('edit.placeGO')}
                        select={
                            <DropdownSelector2
                                placeholder={isCreate && 'Выберите из списка'}
                                options={[
                                    {
                                        id: 'Россия',
                                        value: 'Россия',
                                        text: 'Россия',
                                    },
                                ]}
                                customStyle={{ width: '400px' }}
                                disabled
                            />
                        }
                    />
                    <div className={style.blockGroup}>
                        <div>
                            <div>
                                {t('edit.subject')}
                                <span className={style.star}>*</span>
                            </div>
                            <Form.Item
                                name="region_id"
                                rules={[{ required: true, message: MESSAGE }]}
                            >
                                <DropdownSelector2
                                    placeholder={isCreate && 'Выберите из списка'}
                                    options={regionsOptions}
                                    customStyle={{ width: '492px' }}
                                    defaultValue={generator?.region_id}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <div>{t('edit.index')}</div>
                            <Form.Item
                                name="address_index"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (value && String(value).length !== 6) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Индекс должен состоять из 6 символов',
                                                    ),
                                                )
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    className={style.inputTiny}
                                    placeholder="_ _ _ _ _ _"
                                    maxLength={6}
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={style.blockGroup}>
                        <div>
                            <div>Населенный пункт</div>
                            <Form.Item name="city">
                                <TextArea className={style.textArea} rows={2} maxLength={1000} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={style.blockGroup}>
                        <div>
                            <div>{t('edit.street')}</div>
                            <Form.Item name="street">
                                <Input
                                    placeholder={isCreate && 'Введите название улицы'}
                                    className={style.inputShort}
                                    maxLength={100}
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <div>{t('edit.house')}</div>
                            <Form.Item name="house">
                                <Input className={style.inputTiny} maxLength={10} />
                            </Form.Item>
                        </div>
                        <div>
                            <div>{t('edit.block')}</div>
                            <Form.Item name="housing">
                                <Input className={style.inputTiny} maxLength={10} />
                            </Form.Item>
                        </div>
                        <div>
                            <div>{t('edit.building')}</div>
                            <Form.Item name="building">
                                <Input className={style.inputTiny} maxLength={10} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={style.blockGroup}>
                        <div>
                            <div>{t('edit.lat')}</div>
                            <Form.Item
                                name="geo_lat"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (value != null && (value < -90 || value > 90)) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Значение широты не может быть меньше -90, либо больше 90',
                                                    ),
                                                )
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    className={style.inputMiddle}
                                    placeholder="Укажите широту"
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                        <div>
                            <div>{t('edit.lng')}</div>
                            <Form.Item
                                name="geo_lon"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (value != null && (value < -180 || value > 180)) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Значение долготы не может быть меньше -180, либо больше 180',
                                                    ),
                                                )
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    className={style.inputMiddle}
                                    placeholder="Укажите долготу"
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={style.blockGroup}>
                        <div>
                            <div>{t('edit.addressMore')}</div>
                            <Form.Item name="address_info">
                                <TextArea className={style.textArea} rows={3} maxLength={1000} />
                            </Form.Item>
                        </div>
                    </div>
                    <Divider />
                    <CommonBlock
                        margin
                        label="Показатель степени локализации, %"
                        select={
                            <Form.Item
                                name="localization_degree"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (value != null && (value < 0 || value > 100)) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Значение процента не может быть меньше нуля, либо больше 100',
                                                    ),
                                                )
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input className={style.input} type="number" />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        margin
                        label="Показатель экспорта промышленной продукции, %"
                        select={
                            <Form.Item
                                name="export_rate_of_industrial_products"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (value != null && (value < 0 || value > 100)) {
                                                return Promise.reject(
                                                    new Error(
                                                        'Значение процента не может быть меньше нуля, либо больше 100',
                                                    ),
                                                )
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input className={style.input} type="number" />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        required
                        customStyle={{ marginTop: '28px' }}
                        label={'Квалификационная категория'}
                        select={
                            <Form.Item
                                name="renewable_energy_type_id"
                                rules={[{ required: true, message: MESSAGE }]}
                            >
                                <DropdownSelector2
                                    placeholder={isCreate && 'Выберите из списка'}
                                    options={fullRenewableEnergyTypesOptions}
                                    customStyle={{ width: '400px' }}
                                    defaultValue={fullRenewableEnergyTypesOptions.find(
                                        (item) => item.id === generator?.renewable_energy_type_id,
                                    )}
                                />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        margin
                        label={t('edit.fuelType')}
                        select={
                            <Form.Item name="fuel_ids">
                                <TagSelector
                                    customStyle={{ width: '400px' }}
                                    options={fullFuelOptions || []}
                                />
                            </Form.Item>
                        }
                    />
                    <Alert
                        message={
                            <div className={style.alertHeader}>
                                Указать все виды топлива, использующиеся на ГО
                            </div>
                        }
                        className={style.alert}
                        type="warning"
                        showIcon
                    />
                    <CommonBlock
                        margin
                        required
                        customStyle={{
                            minHeight: '190px',
                            alignItems: 'center',
                        }}
                        label={
                            'Виды возобновляемых источников энергии и иные виды топлива, используемые для производства электрической энергии, либо указание на то, что генерирующий объект является атомной электростанцией'
                        }
                        select={
                            <Form.Item
                                style={{ alignSelf: 'flex-start', marginTop: '5px' }}
                                name="renewable_energy_type_description"
                                rules={[{ required: true, message: MESSAGE }]}
                            >
                                <Input className={style.input} />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        customStyle={{ minHeight: '32px' }}
                        required
                        margin
                        label="Установленная мощность, МВт"
                        select={
                            <Form.Item name="power" rules={[{ required: true, message: MESSAGE }]}>
                                <Input className={style.input} type="number" />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        required
                        label="Дата ввода ГО в эксплуатацию"
                        margin
                        select={
                            <Form.Item
                                name="comissioning_date"
                                rules={[{ required: true, message: MESSAGE }]}
                            >
                                <DatePicker
                                    className={style.date}
                                    placeholder="Выберите дату"
                                    format="DD.MM.YYYY"
                                />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        customStyle={{ height: '32px' }}
                        required
                        margin
                        label={t('edit.manufMode')}
                        select={
                            <Form.Item
                                name="production_mode_id"
                                rules={[{ required: true, message: MESSAGE }]}
                            >
                                <DropdownSelector2
                                    placeholder={isCreate && 'Выберите из списка'}
                                    options={productionModesOptions}
                                    customStyle={{ width: '400px' }}
                                    defaultValue={productionModesOptions.find(
                                        (item) => item.id === generator?.production_mode_id,
                                    )}
                                />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        customStyle={{ height: '32px' }}
                        required
                        margin
                        label={t('edit.ownerBase')}
                        select={
                            <Form.Item
                                name="ownership_id"
                                rules={[{ required: true, message: MESSAGE }]}
                            >
                                <DropdownSelector2
                                    placeholder={isCreate && 'Выберите из списка'}
                                    options={ownershipsOptions}
                                    customStyle={{ width: '400px' }}
                                    defaultValue={ownershipsOptions.find(
                                        (item) => item.id === generator?.ownership_id,
                                    )}
                                />
                            </Form.Item>
                        }
                    />
                    {id && (
                        <>
                            <CommonBlock
                                customStyle={{ height: '32px' }}
                                required
                                margin
                                label={t('edit.ownerGO')}
                                select={
                                    <Input className={style.input} value={goOwnerName} disabled />
                                }
                            />
                            <Form.Item hidden name="uid_go_owner" />
                        </>
                    )}
                    <CommonBlock
                        customStyle={{ height: '32px' }}
                        margin
                        label={t('edit.support')}
                        select={
                            <Form.Item name="aid_id">
                                <DropdownSelector2
                                    placeholder={isCreate && 'Выберите из списка'}
                                    options={aidsOptions}
                                    customStyle={{ width: '400px' }}
                                    noDefaultValue
                                    allowClear
                                />
                            </Form.Item>
                        }
                    />
                    {goTypeId === 1 && (
                        <>
                            <CommonBlock
                                required
                                label="№ квалификационного свидетельства"
                                margin
                                select={
                                    <Form.Item
                                        name="qualification_number"
                                        rules={[{ required: true, message: MESSAGE }]}
                                    >
                                        <Input
                                            placeholder={isCreate && 'Введите номер свидетельства'}
                                            className={style.input}
                                            maxLength={100}
                                        />
                                    </Form.Item>
                                }
                            />
                            <CommonBlock
                                required
                                label="Дата квалификации"
                                margin
                                select={
                                    <Form.Item
                                        name="qualification_date"
                                        rules={[{ required: true, message: MESSAGE }]}
                                    >
                                        <DatePicker
                                            className={style.date}
                                            placeholder="Выберите дату"
                                            format="DD.MM.YYYY"
                                            disabledDate={(date) =>
                                                !date.isBefore(moment().endOf('day'))
                                            }
                                        />
                                    </Form.Item>
                                }
                            />
                            <CommonBlock
                                customStyle={{ marginTop: '24px' }}
                                label="Отметка о выдаче дубликата квалификационного свидетельства"
                                select={
                                    <Form.Item name="qualification_point">
                                        <Input className={style.input} maxLength={100} />
                                    </Form.Item>
                                }
                            />
                            <CommonBlock
                                customStyle={{ marginTop: '40px' }}
                                label="Дата последней проверки"
                                select={
                                    <Form.Item name="last_check_date">
                                        <DatePicker
                                            className={style.date}
                                            placeholder="Выберите дату"
                                            format="DD.MM.YYYY"
                                            disabledDate={(date) =>
                                                !date.isBefore(moment().endOf('day'))
                                            }
                                        />
                                    </Form.Item>
                                }
                            />
                        </>
                    )}
                    <CommonBlock
                        margin
                        customStyle={{
                            minHeight: '90px',
                            alignItems: 'center',
                        }}
                        label={
                            'Установленная мощность и дата ввода в эксплуатацию электроустановок в составе ГО'
                        }
                        select={
                            <Form.Item
                                style={{ alignSelf: 'flex-start', marginTop: '5px' }}
                                name="electrical_installations_power"
                            >
                                <Input className={style.input} />
                            </Form.Item>
                        }
                    />
                    <Divider />
                    <div style={{ marginLeft: '215px' }}>
                        <Form.Item
                            name="selectedFiles"
                            rules={[{ required: true, message: MESSAGE }]}
                        >
                            <DocumentsBlock
                                value={generator?.documents}
                                customStyle={{ width: '400px' }}
                            />
                        </Form.Item>
                    </div>
                    {type === 'create' && (
                        <>
                            <Divider />
                            <div className={style.buttonWrapper}>
                                <PrimaryButton
                                    onClick={getBack}
                                    value="Отмена"
                                    isCancel
                                    customStyle={{ padding: '8px 16px' }}
                                />
                                <PrimaryIconButton
                                    size="large"
                                    // disabled={true}
                                    onClick={handleConfirm}
                                    value={id ? 'Сохранить' : 'Создать'}
                                    customStyle={{ padding: '8px 16px' }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Form>
            <SearchTSO
                isModalVisible={isSearchTSOModalVisible}
                onCancel={() => setIsSearchTSOModalVisible(false)}
                onSelect={(tso) => {
                    setSelectedTso(tso)
                    setIsSearchTSOModalVisible(false)
                }}
            />
            <SearchGP
                isModalVisible={isSearchGPModalVisible}
                onCancel={() => setIsSearchGPModalVisible(false)}
                onSelect={(gp) => {
                    setSelectedGp(gp)
                    setIsSearchGPModalVisible(false)
                }}
            />
            <AddEditTSO
                isModalVisible={openAddEditTso}
                onCancel={() => setOpenAddEditTso(false)}
                onChange={(tso) => {
                    setOpenAddEditTso(false)
                    setSelectedTso(tso)
                }}
            />
            <AddEditGP
                isModalVisible={openAddEditGp}
                onCancel={() => setOpenAddEditGp(false)}
                onChange={(gp) => {
                    setOpenAddEditGp(false)
                    setSelectedGp(gp)
                }}
            />
            <QualificationCategoryModal
                isModalVisible={isQualificationCategoryModalVisible}
                onClose={() => setIsQualificationCategoryModalVisible(false)}
            />
        </>
    )
}

export default GeneratorLayoutAdmin
