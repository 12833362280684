import { FC, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import {Card, ImportButton, CenteredSpin, IFile, PrimaryButton} from '../ui'
import { Box } from '../ui/Box/Box'
import { useSelector } from 'react-redux'
import { ButtonsBlock } from '../Edit/components'
import {Alert, Checkbox, Divider, message} from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import { sendRepayment, sendWithFile, getFilePrice } from './config'
import { ArrowLeft, AddCircle } from '../../assets/icons'
import { currencyFormat, signBody } from '../helpers'
import DocumentsBlock from '../AdminUploading/components/DocumentsBlock/DocumentsBlock'
import { ConsumerBlock } from './components'
import { useDebouncedRequest, useGetTariffRate } from '../hooks'
import IAppState from 'store/states'
import { useCurrentUser } from '../hooks/useCurrentUser'
import request from '../../services/request'
import MessageComponent from '../ui/Messages/MessageComponent'
import { getLimitVolume, getPackages } from '../Profile/components/PaymentAccount/configurations'
import useMount from '../hooks/useMount'

const addingOptions = [
    { label: 'Вручную', id: '8q7f77bf-a3ec-4441-9b13-e46eabc3c027' },
    { label: 'Из файла', id: 'jr324494-8d32-423a-a7d8-b038ece4a70b' },
]

const CertificateRepayment: FC = () => {
    const { t } = useTranslation()
    const { state } = useLocation()
    const { volume, certificateId } = state
    const navigate = useNavigate()
    const [option, setOption] = useState(addingOptions[0].label)
    const [total, setTotal] = useState(0)
    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo
    const [limitState, setLimitState] = useState(0)
    const { isUserAdmin, uid } = useCurrentUser()
    const { fetch: fetchPackages, result: packagesResult } = useRequest(getPackages)
    const isManual = option === 'Вручную'
    const [files, setFiles] = useState<IFile[]>([])
    const [file, setFileState] = useState<File>()
    const [activeStatus, setActiveStatus] = useState<number>(0)

    const fileHandler = (list: any) => {
        setFiles(list)
    }
    
    useEffect(() => {
        fetchPackages({ getParams: { uid: uid } })
    }, [])
    async function getVolume() {
        const { data: profile } = await request.get('/api/users/v1/profile?need_latest=1')
        const { data: res } = await request.get(getLimitVolume.link, {
            params: {
                user_id: profile.uid,
            },
        })
        setLimitState(res.detail)
    }

    useEffect(() => {
        if (activeStatus === 1) {
            getVolume()
        }
    }, [activeStatus])

    useEffect(() => {
        if (packagesResult) {
            setActiveStatus(packagesResult?.active[0]?.status_id)
        }
    }, [packagesResult])
    
    const setFile = (file: File) => {
        setFileState(file)
    }

    const { fetch: fetchFilePrice, result: filePrice } = useRequest(getFilePrice)

    const [freeRepaymentChecked, setFreeRepaymentChecked] = useState<boolean>(true)
    const [selectedRepayments, setSelectedRepayments] = useState<any>([{ user_id: '', volume: 0 }])
    const [unregistredRepayments, setUnregistredRepayments] = useState<any[]>([
        { unreg_consumer_data: {}, volume: 0 },
    ])
    const stateRef = useRef()
    const stateUnregistredRef = useRef()
    stateRef.current = selectedRepayments
    stateUnregistredRef.current = unregistredRepayments

    const updateSelectedRepayments = (index: number, key: string, value: number | string) => {
        const arrayCopy = !stateRef?.current?.[index]
            ? [...stateRef.current, { user_id: '', volume: 0 }]
            : [...stateRef.current]
        arrayCopy[index][key] = value
        setSelectedRepayments(arrayCopy)
    }

    const updateUnregistredRepayments = (index: number, key: string, value: number | string) => {
        const arrayCopy = !stateUnregistredRef?.current?.[index]
            ? [...stateUnregistredRef.current, { volume: 0, unreg_consumer_data: {} }]
            : [...stateUnregistredRef.current]
        arrayCopy[index][key] = value
        setUnregistredRepayments(arrayCopy)
    }

    const { fetch: fetchOtherTarifs, result } = useRequest({
        link: '/api/billing/v1/tariff-rate',
        method: RequestTypes.post,
    })

    const noOwnerList = selectedRepayments.filter(
        (el: { user_id: string; volume: number }) => el.user_id !== uid && !!el.user_id,
    )

    const unregList = unregistredRepayments.filter(
        (el: { unreg_consumer_data: { inn: string }; volume: number }) =>
            !!el.unreg_consumer_data?.inn,
    )

    const isOther = noOwnerList.length > 0 || unregList.length > 0

    const isEmplyList =
        selectedRepayments.filter((el: { user_id: string; volume: number }) => el.volume > 0)
            .length < 1

    const fetchOterTarrifs = (volume: number) => {
        fetchOtherTarifs({
            body: {
                operation_type_id: 85,
                volume,
            },
            showError: false,
        })
    }

    const debouncedSendVolume = useDebouncedRequest(fetchOterTarrifs, 500)

    useEffect(() => {
        const notEmpty = selectedRepayments.filter(
            (el: { user_id: string; volume: number }) => !!el.volume,
        )
        const res = notEmpty.reduce((sum, current) => sum + current.volume, 0)
        setTotal(res)

        const otherVolume = [...noOwnerList, ...unregList].reduce(
            (sum, current) => sum + current.volume,
            0,
        )
        debouncedSendVolume(otherVolume)
    }, [selectedRepayments, unregistredRepayments])

    useEffect(() => {
        if (files.length && !isManual) {
            fetchFilePrice({
                body: {
                    certificate_id: certificateId,
                    document: { id: files[0].id, hash: files[0].hash },
                },
                errorCodeMessage: true,
            })
        }
    }, [files])

    const [componentsArray, setComponentsArray] = useState<any>([
        <ConsumerBlock
            totalVolume={volume}
            updateRepayments={updateSelectedRepayments}
            updateUnregistredRepayments={updateUnregistredRepayments}
            index={0}
        />,
    ])

    const incrementComponentsArray = () => {
        setComponentsArray([
            ...componentsArray,
            <ConsumerBlock
                totalVolume={volume}
                updateRepayments={updateSelectedRepayments}
                index={componentsArray.length}
                updateUnregistredRepayments={updateUnregistredRepayments}
            />,
        ])
    }

    const { fetch } = useRequest(sendRepayment)
    const { fetch: fetchWithFile } = useRequest(sendWithFile)
    const { fetch: fetchAgreements } = useRequest({
        link: `api/extension/v1/users/pers_agreements`,
        method: RequestTypes.post,
    })
    const onChange = (value: string) => {
        setOption(value)
    }

    const getBack = () => {
        navigate(-1)
    }

    const confirmRepayment = async (free: boolean) => {
        const body = isManual
            ? {
                certificate_id: certificateId,
                repayments: selectedRepayments.filter((data) => !!data.user_id),
                unreg_repayments: unregistredRepayments.filter(
                    (data) => Object.keys(data.unreg_consumer_data).length > 0,
                ),
                free: free,
            }
            : {
                certificate_id: certificateId,
                document: { id: files[0].id, hash: files[0].hash },
                free: free,
            }

        if (isManual) {
            const { request_id, signature } = await signBody(body, data)
            fetch({
                body,
                getParams: {
                    request_id,
                },
                config: {
                    headers: {
                        Signature: signature,
                    },
                },
                successMessage: {
                    title: t('detailedCard.success'),
                    description: 'Сертификат успешно погашен',
                },
                onSuccess: () => navigate(isUserAdmin ? '/green-tools' : '/user-my-green-tools'),
                errorCodeMessage: true,
            })
        } else {
            const formdata = new FormData()
            formdata.append('file', file)
            fetchAgreements({
                body: formdata,
                onError: (err: any) => {
                    message.error(err.detail)
                },
                showError: false,
                onSuccess: async () => {
                    const { request_id, signature } = await signBody(body, data)
                    fetchWithFile({
                        body,
                        getParams: {
                            request_id,
                        },
                        config: {
                            headers: {
                                Signature: signature,
                            },
                        },
                        successMessage: {
                            title: t('detailedCard.success'),
                            description: 'Сертификат успешно погашен',
                        },
                        onSuccess: () =>
                            navigate(isUserAdmin ? '/green-tools' : '/user-my-green-tools'),
                        errorCodeMessage: true,
                    })
                },
            })
        }
    }

    const { isLoading, rate } = useGetTariffRate({
        id: 30,
        volume,
        showError: false,
    })

    const confirmDisableCondition =
        (isManual && (!(volume === total) || isEmplyList)) || (!isManual && !files.length) ||
        activeStatus === 1 && volume <= limitState && freeRepaymentChecked

    const confirmFreeRepaymentDisableCondition =
        (isManual && (!(volume === total) || isEmplyList)) || (!isManual && !files.length) ||
        volume > limitState || !freeRepaymentChecked

    if (isLoading) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div className={style.mainHeader}>Погашение сертификата</div>
            </div>

            <Card className={style.repaymentCard}>
                <div className={style.title}>Как Вы хотите добавить потребителей ЭЭ?</div>
                <Box direction="row" gap={'16px'}>
                    {addingOptions?.map((item) => {
                        const { label, id } = item
                        return (
                            <>
                                <div
                                    className={
                                        option === label ? style.greenToolSelected : style.greenTool
                                    }
                                    key={id}
                                    onClick={() => onChange(label)}
                                >
                                    {label}
                                </div>
                            </>
                        )
                    })}
                </Box>
                {isManual ? (
                    <>
                        {componentsArray}
                        <div className={style.buttonsBlock}>
                            <ImportButton
                                value="Добавить потребителя"
                                icon={<AddCircle />}
                                onClick={incrementComponentsArray}
                            />
                        </div>
                    </>
                ) : (
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        setFile={setFile}
                        hideComment
                        serviceId={4}
                        description="Загрузите файл, из которого хотите импортировать данные (формат - xml, не более 50 мб)"
                        format="xml"
                        customStyle={{ width: '440px', marginTop: '24px' }}
                    />
                )}
                <Divider />
                <div className={style.repaymentItemWrapper}>
                    <div className={style.repaymentItemName}>Объем</div>
                    <div className={style.repaymentItemValue}>{`${volume} кВт*ч`}</div>
                </div>
                {rate !== '0 ₽' && (
                    <div className={style.repaymentItemWrapper}>
                        <div className={style.repaymentItemName}>Оплата за погашение СП</div>
                        <div className={style.repaymentItemValue}>{
                            activeStatus === 1 && volume <= limitState && freeRepaymentChecked
                                ? '0 ₽' 
                                : rate
                        }</div>
                    </div>
                )}
                {(filePrice || (isOther && result !== '0 ₽')) && (
                    <div className={style.repaymentItemWrapper}>
                        <div className={style.repaymentItemName}>
                            Оплата за передачу АГ третьим лицам
                        </div>
                        <div className={style.repaymentItemValue}>{`${
                            activeStatus === 1 && volume <= limitState && freeRepaymentChecked 
                                ? 0 
                                : filePrice
                                    ? currencyFormat(filePrice?.fee)
                                    : result?.rate
                                        ? currencyFormat(result?.rate)
                                        : 0
                        } ₽`}</div>
                    </div>
                )}
                {activeStatus === 1 && (
                    <div>
                        {volume > limitState && (
                            <Alert
                                message={'Вам недоступно  бесплатное погашение данного сертификата'}
                                type={'warning'}
                                showIcon
                                className={style.alert}
                            />
                        )}
                        {volume <= limitState && (
                            <>
                                <Alert
                                    message={
                                        'Вам доступно бесплатное погашение данного сертификата'
                                    }
                                    type={'success'}
                                    showIcon
                                    className={style.alert}
                                />
                                <Checkbox
                                    checked={freeRepaymentChecked}
                                    className={style.checkbox}
                                    onChange={(event) => {
                                        setFreeRepaymentChecked(event.target.checked)
                                    }}
                                >
                                    Погасить бесплатно
                                </Checkbox>
                            </>
                        )}
                    </div>
                )}
                <Divider />
                <div className={style.buttonsWrapper}>
                    <ButtonsBlock
                        confirmTitle={isUserAdmin ? 'Погасить' : 'Подписать и погасить'}
                        cancelAction={getBack}
                        confirmAction={() => confirmRepayment(false)}
                        confirmDisabled={confirmDisableCondition}
                    />
                    {activeStatus === 1 && (
                        <PrimaryButton
                            disabled={confirmFreeRepaymentDisableCondition}
                            value={
                                isUserAdmin
                                    ? 'Погасить бесплатно'
                                    : 'Подписать и погасить бесплатно'
                            }
                            customStyle={{ marginLeft: '12px', padding: '8px 16px' }}
                            onClick={() => confirmRepayment(true)}
                        ></PrimaryButton>
                    )}
                </div>
            </Card>
        </div>
    )
}

export default CertificateRepayment
