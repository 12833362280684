import { ConfigProvider, Table } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ruRU from 'antd/lib/locale/ru_RU'
import Filter from '../Filter/Filter'

import style from './style.m.less'

import { columns } from './columns'
import MoreFilters from '../MoreFilters/MoreFilters'
import { IData, isAddedType } from './data'
import { userRoleMap } from '../../../../constants/approval'
import moment from 'moment'
import debounce from 'lodash/debounce'
import useRequest from '../../../hooks/useRequest'
import { getAuditEvent, getAuditEventTypes } from '../../config'
import useMount from '../../../hooks/useMount'

interface ILoadEvents {
    pagination: number
    dateFilter: [moment.Moment, moment.Moment] | null
    typeEventIdFilter: number[]
    nameCreatorFilter: string
    roleCreatorFilter: number
    nameRecipientFilter: string
    roleRecipientFilter: number
    pageSize: number
}

interface IDefault {
    res: any
    events: any
}

const PAGE_SIZE = 10

const Default: FC<IDefault> = () => {
    const navigate = useNavigate()

    const { fetch: fetchTypes, isLoading: isLoadingTypes } = useRequest(getAuditEventTypes)
    const { fetch: fetchEvents, isLoading, result } = useRequest(getAuditEvent)
    const [eventTypes, setEventTypes] = useState<{
        options: any[]
        dictionary: any
    }>({
        options: [],
        dictionary: {},
    })
    const [pagination, setPagination] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [nameCreatorFilter, setNameCreatorFilter] = useState('')
    const [roleCreatorFilter, setRoleCreatorFilter] = useState(0)
    const [typeEventIdFilter, setTypeEventIdFilter] = useState<number[]>([])
    const [nameRecipientFilter, setNameRecipientFilter] = useState('')
    const [roleRecipientFilter, setRoleRecipientFilter] = useState(0)
    const [dateFilter, setDateFilter] = useState<[moment.Moment, moment.Moment] | null>(null)

    const onChangeNameCreator = (value: any) => { setPagination(1); setNameCreatorFilter(value) }
    const onChangeRoleCreator = (value: any) => { setPagination(1); setRoleCreatorFilter(value) }
    const onChangeEventId = (value: any) => { setPagination(1); setTypeEventIdFilter(value) }
    const onChangeNameRecipient = (value: any) => { setPagination(1); setNameRecipientFilter(value) }
    const onChangeRoleRecipient = (value: any) => { setPagination(1); setRoleRecipientFilter(value) }
    const onChangeDate = (value: any) => { setPagination(1); setDateFilter(value) }

    useMount(() =>
        fetchTypes({
            onSuccess(data) {
                const eventOptions = data.map((item: any) => {
                    return {
                        id: item.id,
                        value: item.id,
                        text: item.name,
                    }
                })
                setEventTypes({
                    options: [...eventOptions],
                    dictionary: eventOptions.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.text })),
                })
            },
        }),
    )

    const loadEvents = useCallback(
        async ({
            pagination,
            dateFilter,
            typeEventIdFilter,
            nameCreatorFilter,
            roleCreatorFilter,
            nameRecipientFilter,
            roleRecipientFilter,
            pageSize,
        }: ILoadEvents) => {
            fetchEvents({
                body: {
                    limit: pageSize,
                    offset: (pagination - 1) * pageSize,
                    filters: {
                        event_date_from: dateFilter
                            ? moment(dateFilter[0]).startOf('day')
                            : undefined,
                        event_date_to: dateFilter ? moment(dateFilter[1]).endOf('day') : undefined,
                        type_event_ids: typeEventIdFilter,
                        name_creator: nameCreatorFilter,
                        role_creator: roleCreatorFilter,
                        name_recipient: nameRecipientFilter,
                        role_recipient: roleRecipientFilter,
                    },
                },
            })
        },
        [],
    )
    const events = result?.events
    const total = result?.total
    const loadEventsDebounced = useCallback(debounce(loadEvents, 300), [])

    useEffect(() => {
        loadEventsDebounced({
            pagination,
            nameCreatorFilter,
            roleCreatorFilter,
            typeEventIdFilter,
            nameRecipientFilter,
            dateFilter,
            roleRecipientFilter,
            pageSize,
        })
    }, [
        pagination,
        pageSize,
        nameCreatorFilter,
        roleCreatorFilter,
        typeEventIdFilter,
        nameRecipientFilter,
        dateFilter,
        roleRecipientFilter,
    ])

    const getDataSource = (): IData[] =>
        events?.map(
            (item: {
                id: string
                ts_event: any
                type_event_id: number
                uid_creator: string
                name_creator: string
                role_creator: number
                uid_recipient: number
                name_recipient: string
                role_recipient: number
            }) => {
                const creatorRole = userRoleMap.get(item.role_creator)!
                const recipientRole = item.role_recipient == 0 ? "Не зарегистрирован" : userRoleMap.get(item.role_recipient)!

                return {
                    key: item.id,
                    id: item.id,
                    date: item.ts_event,
                    eventId: item.type_event_id,
                    eventType: eventTypes.dictionary[item.type_event_id],
                    creatorRole: creatorRole,
                    creatorName: item.name_creator,
                    recipientName: item.name_recipient,
                    recipientRole: recipientRole,
                }
            },
        )

    const openedDataSource = getDataSource()

    return (
        <div className={style.opened}>
            <Filter
                eventTypesOptions={eventTypes.options}
                onChangeEventId={onChangeEventId}
                onChangeNameCreator={onChangeNameCreator}
                onChangeRoleCreator={onChangeRoleCreator}
                onChangeNameRecipient={onChangeNameRecipient}
                onChangeDate={onChangeDate}
            />
            <MoreFilters onChangeRoleRecipient={onChangeRoleRecipient} />
            <div className={style.table}>
                <ConfigProvider locale={ruRU}>
                    <Table
                        dataSource={openedDataSource}
                        columns={columns}
                        pagination={{
                            position: ['bottomLeft'],
                            onChange: (page, page_size) => {
                                setPagination(page)
                                setPageSize(page_size)
                            },
                            total: total,
                            pageSize: pageSize,
                            current: pagination,
                            showSizeChanger: true,
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                if (isAddedType(record.eventId)) {
                                    navigate('/audit/' + record.key, {
                                        state: { eventData: record },
                                    })
                                }
                            },
                        })}
                        loading={isLoading || isLoadingTypes}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
}

export default Default
