import { Mail2, Telegram2 } from '../../assets/icons'
import { FC } from 'react'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

export const Contacts: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.contacts}>
            <div>
                <h2 className={style.contactsTitle}>{t('documents.contacts')}</h2>
                <p className={style.contactsText}>{t('documents.address')}</p>
                <div className={style.contactsNumbers}>
                    <p className={style.contactsNumber}>+7 (495) 740-30-27</p>
                </div>
                <div className={style.media}>
                    <div>Для получения технической поддержки, а также по вопросам регистрации и операций в Системе Реестра:</div>
                    <div><a href="mailto:help@g-e-t.ru">help@g-e-t.ru</a></div>
	                <div>Подача заявлений на квалификацию генерирующих объектов в электронном виде, по вопросам, касающимся квалификации генерирующих объектов и заключения договоров:</div>
                    <div><a href="mailto:mail@g-e-t.ru">mail@g-e-t.ru</a></div>
                </div>
            </div>
            <div>
                <iframe
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A736c7e8620775dadfaba7b183bbed1e1955b29b09263f9f3e6d91b8d7b8273b6&amp;source=constructor"                    
                    width="848"
                    height="369"
                    frameborder="0"
                ></iframe>
            </div>
        </div>
    )
}

export default Contacts
