import moment from 'moment'
import { userRolePicker, monthPicker, currencyFormat } from '../../../helpers'
import { AGRealisationEventDetails, AuditEventDetails } from './types'
import { IConsumer } from '../SeparateConsumersTable'

const createCodes = (codesArray: string[]) => `${codesArray.toString()}`

export const restorePasswordConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails
    return {
        visibleInfo: [
            {
                name: 'Логин',
                value: payload.login,
                noMargin: true,
            },
            {
                name: 'E-mail',
                value: payload.email,
            },
        ],
    }
}

export const commonInfoConfig = (eventDetails: any) => {
    return {
        visibleInfo: [
            {
                name: 'Дата и время операции',
                value: moment(eventDetails.ts_event).format('DD.MM.YYYY HH:mm'),
                noMargin: true,
            },
            {
                name: 'Исполнитель',
                value: eventDetails.name_creator,
                role: `(${userRolePicker(eventDetails.role_creator)})`,
            },
            {
                name: 'Адресат действия',
                value: eventDetails.name_recipient,
                role: `(${userRolePicker(eventDetails.role_recipient)})`,
            },
        ],
    }
}

export const changeEmailConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails
    return {
        visibleInfo: [
            {
                name: 'Старый e-mail',
                value: payload.email,
                noMargin: true,
            },
            {
                name: 'Новый e-mail',
                value: payload.email_new,
            },
        ],
    }
}

export const approveGOConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails
    return {
        visibleInfo: [
            {
                name: 'Название ЗГО',
                value: payload.go_name,
                noMargin: true,
            },
            {
                name: 'Ссылка на операцию',
                value: 'Создание ЗГО',
                link: {
                    path: `/audit/history/${payload.ext_event_id}`,
                    state: {
                        eventData: {
                            eventType: 'Добавление ГО',
                            id: payload.ext_event_id,
                            eventId: 65,
                        },
                    },
                },
            },
        ],
    }
}

export const activateGOConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails
    return {
        visibleInfo: [
            {
                name: 'Название ГО',
                value: payload.go_name,
                noMargin: true,
                link: {
                    path: `/generator-card/${payload.generator_id}`,
                },
            },
        ],
    }
}

export const closeAccountConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Номер лицевого счета',
                value: `№${payload.account_number}`,
                noMargin: true,
            },
            {
                name: 'Название лицевого счета',
                value: payload.account_name,
            },
            {
                name: 'Стоимость',
                value: payload.service_fee,
            },
        ],
    }
}

export const accountServiceConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails
    const periodString =
        (payload.begin_ts ? moment(payload.begin_ts).format('DD.MM.YYYY') : '') +
        (payload.end_ts ? ` - ${moment(payload.end_ts).format('DD.MM.YYYY')}` : '')

    return {
        visibleInfo: [
            {
                name: 'Номер лицевого счета',
                value: `№${payload.account_number}`,
                noMargin: true,
            },
            {
                name: 'Название лицевого счета',
                value: payload.account_name,
            },
            {
                name: 'Стоимость',
                value: payload.service_fee,
            },
            {
                name: 'Оплачиваемый период',
                value: periodString,
            },
        ],
    }
}

export const changeAccountNumber = (eventDetails: AuditEventDetails<any>, dictionaries: any) => {
    const { payload } = eventDetails
    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: 'Номер ЛС, где находился СП',
                value: `№${payload.account_number}`,
                noMargin: true,
            },
            {
                name: 'Номер нового ЛС',
                value: `№${payload.account_new}`,
            },
            {
                isDivider: true,
                noMargin: true,
            },
            {
                name: 'Номер СП',
                value: payload.certificate_number,
                link: {
                    path: `/user-certificate-card/${payload.certificate_number}`,
                    state: {
                        toolType: 'Сертификаты происхождения',
                        cardLabel: 'Сертификат происхождения',
                        id: payload.certificate_id,
                        isOwner: false,
                    },
                },
            },
            {
                name: 'Вид энергии',
                value: getEnergyType(payload.energy_type),
            },
            {
                name: 'Объем, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Наименование ГО',
                value: payload.generator_name,
                link: {
                    path: `/generator-card/${payload.generator_id}`,
                },
            },
            {
                name: 'Период производства АГ',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
            {
                name: 'Дата создания СП',
                value: moment(payload.created_ts).format('DD.MM.YYYY'),
            },
            {
                name: 'Период действия СП',
                value: `до ${moment(payload.expires_ts).format('DD.MM.YYYY')}`,
            },
        ],
    }
}

export const openCertificateConfig = (eventDetails: AuditEventDetails<any>, dictionaries: any) => {
    const { payload } = eventDetails
    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: 'Номер ЛС, где находился СП',
                value: `№${payload.account_number}`,
                noMargin: true,
            },
            {
                name: 'Номер СП',
                value: payload.certificate_number,
                link: {
                    path: `/user-certificate-card/${payload.certificate_number}`,
                    state: {
                        toolType: 'Сертификаты происхождения',
                        cardLabel: 'Сертификат происхождения',
                        id: payload.certificate_id,
                        isOwner: false,
                    },
                },
            },
            {
                name: 'Вид энергии',
                value: getEnergyType(payload.energy_type),
            },
            {
                name: 'Объем, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Название ГО',
                value: payload.generator_name,
            },
            {
                name: 'Период производства АГ',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
            {
                name: 'Дата создания СП',
                value: moment(payload.created_ts).format('DD.MM.YYYY'),
            },
            {
                name: 'Срок действия СП',
                value: `до ${moment(payload.expires_ts).format('DD.MM.YYYY')}`,
            },
        ],
    }
}

export const sendSPtoETPConfig = (eventDetails: AuditEventDetails<any>, dictionaries: any) => {
    const { payload } = eventDetails

    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: 'Номер СП',
                value: payload.certificate_number,
                link: {
                    path: `/user-certificate-card/${payload.certificate_id}`,
                    state: {
                        toolType: 'Сертификаты происхождения',
                        cardLabel: 'Сертификат происхождения',
                        id: payload.certificate_id,
                        isOwner: false,
                    },
                },
            },
            {
                name: 'Владелец СП',
                value: payload.owner_name,
                link: {
                    path: `/owner/${payload.owner_id}`,
                },
            },
            {
                name: 'Вид энергии',
                value: getEnergyType(payload.energy_type),
            },
            {
                name: 'Объем, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Название ГО',
                value: payload.go_name,
                link: {
                    path: `/generator-card/${payload.go_id}`,
                },
            },
            {
                name: 'Период производства АГ',
                value: `${moment(payload.period).format('MMMM YYYY')}`,
            },
            {
                name: 'Дата создания СП',
                value: moment(payload.cert_create_ts).format('DD.MM.YYYY'),
            },
            {
                name: 'Срок действия СП',
                value: `до ${moment(payload.cert_expired_ts).format('DD.MM.YYYY')}`,
            },
        ],
    }
}

export const changeCertificateOwnerConfig = (
    eventDetails: AuditEventDetails<any>,
    dictionaries: any,
) => {
    const { payload } = eventDetails
    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: 'Новый владелец',
                value: payload.name_new,
                noMargin: true,
            },
            {
                name: 'Номер ЛС, где находится СП у нового владельца',
                value: `№${payload.account_new}`,
            },
            {
                name: 'Прежний владелец',
                value: payload.name,
                noMargin: true,
            },
            {
                name: 'Номер ЛС, где находился СП у прежнего владельца',
                value: `№${payload.account_number}`,
            },
            {
                name: 'Номер СП',
                value: payload.certificate_number,
            },
            {
                name: 'Вид энергии',
                value: getEnergyType(payload.energy_type),
            },
            {
                name: 'Объем, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Название ГО',
                value: payload.generator_name,
            },
            {
                name: 'Период производства АГ',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
            {
                name: 'Дата создания СП',
                value: moment(payload.created_ts).format('DD.MM.YYYY'),
            },
            {
                name: 'Срок действия СП',
                value: `до ${moment(payload.expires_ts).format('DD.MM.YYYY')}`,
            },
        ],
    }
}

export const openAccountConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails
    const periodString =
        (payload.begin_ts ? moment(payload.begin_ts).format('DD.MM.YYYY') : '') +
        (payload.end_ts ? ` - ${moment(payload.end_ts).format('DD.MM.YYYY')}` : '')

    return {
        visibleInfo: [
            {
                name: 'Номер лицевого счета',
                value: `№${payload.account_number}`,
                noMargin: true,
            },
            {
                name: 'Название лицевого счета',
                value: payload.account_name,
            },
            {
                name: 'Стоимость',
                value: payload.service_fee,
            },
            {
                name: 'Период действия',
                value: periodString,
            },
            {
                name: 'Главный лицевой счет',
                value: payload.is_main ? 'Да' : 'Нет',
            },
        ],
    }
}

export const createGOConfig = (eventDetails: AuditEventDetails<any>, dictionaries: any) => {
    const { payload } = eventDetails
    const {
        regionsMap,
        qualificationCategoriesMap,
        categoriesGoMap,
        fuelMap,
        productionModesMap,
        ownershipsMap,
        aidsMap,
        attributeTypesMap,
        renewableEnergyTypesMap,
    } = dictionaries
    const getEnergyType = (item: number) => attributeTypesMap?.get(item)
    const getEnergyTypeShort = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: 'Название ЗГО',
                value: payload.name,
                noMargin: true,
            },
            {
                name: 'Рынок ЭЭ',
                value: payload.is_for_retail
                    ? 'Розничный рынок электроэнергии и мощности'
                    : 'Оптовый рынок электроэнергии и мощности',
            },
            {
                name: 'Тип ГО',
                value: `${
                    payload.go_type_id === 1
                        ? 'Квалифицированный генерирующий объект'
                        : 'Зарегистрированный генерирующий объект'
                }`,
            },
            {
                name: 'Уникальный код ГО',
                value: payload.generator_pub_id,
                isTag: true,
            },
            {
                name: 'Код ГТП генерации',
                value: payload.point_delivery_code,
                isTag: true,
            },
            {
                name: 'Код ГТП потребления',
                value: payload.point_delivery_code,
                isTag: true,
            },
            {
                name: 'Владелец ГО',
                value: payload.go_owner.company_name_short,
            },
            {
                name: 'ТСО',
                value: payload.tso?.company_name_short,
            },
            {
                name: 'ГП',
                value: payload.gp?.company_name_short,
            },
            {
                name: 'Местонахождение ГО',
                value: `Российская Федерация${
                    regionsMap.get(payload.region_id)
                        ? ', ' + regionsMap.get(payload.region_id)
                        : ''
                }${payload.city && ', ' + payload.city}${payload.street && ', ' + payload.street}${
                    payload.house && ', ' + payload.house
                }`,
            },
            {
                name: 'Координаты (широта)',
                value: String(payload.geo_lat),
            },
            {
                name: 'Координаты (долгота)',
                value: String(payload.geo_lon),
            },
            {
                name: 'Отнесение ГО к возобновляемой генерации и (или) низкоуглеродной генерации по итогам квалификации генерирующего объекта.',
                value: getEnergyType(payload.renewable_energy_type_id),
            },
            {
                name: 'Показатель степени локализации',
                value: payload.localization_degree
                    ? payload.localization_degree + '%'
                    : 'отсутствует',
            },
            {
                name: 'Показатель экспорта промышленной продукции',
                value: payload.export_rate_of_industrial_products
                    ? payload.export_rate_of_industrial_products + '%'
                    : 'отсутствует',
            },
            {
                name: 'Квалификационная категория',
                value: payload.renewable_energy_type_id
                    ? getEnergyTypeShort(payload.renewable_energy_type_id)
                    : 'отсутствует',
            },
            {
                name: 'Типы топлива',
                value: payload?.fuel_ids?.length ? fuelMap.get(payload?.fuel_ids) : 'отсутствуют',
            },
            {
                name: 'Виды возобновляемых источников энергии и иные виды топлива, используемые для производства электрической энергии, либо указание на то, что генерирующий объект является атомной электростанцией',
                value: payload.renewable_energy_type_description,
            },
            {
                name: 'Дата ввода ГО в эксплуатацию',
                value: payload.comissioning_date
                    ? moment(payload.comissioning_date).format('DD.MM.YYYY')
                    : undefined,
            },
            {
                name: 'Установленная мощность',
                value: (payload.power / 1000).toLocaleString() + ' МВт',
            },
            {
                name: 'Режим производства',
                value: payload.production_mode_id
                    ? productionModesMap.get(payload.production_mode_id)
                    : 'отсутствует',
            },
            {
                name: 'Основания владения',
                value: payload.ownership_id
                    ? ownershipsMap.get(payload.ownership_id)
                    : 'отсутствуют',
            },
            {
                name: 'Программа поддержки',
                value: payload.aid_id ? aidsMap.get(payload.aid_id) : 'отсутствует',
            },
            {
                name: 'Установленная мощность и дата ввода в эксплуатацию электроустановок в составе ГО',
                value: payload.electrical_installations_power,
            },
        ],
    }
}

// конфиг для операции Погашение СП (id = 30)
export const repaymentConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Номер СП',
                value: payload.certificate_number,
                noMargin: true,
            },
            {
                name: 'Период погашения',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
            {
                name: 'Погашенный объем, кВт*ч',
                value: currencyFormat(payload.volume),
            },
        ],
    }
}

// конфиг для операции Реализация АГ по ЗД (id = 67)
export const repaymentConfigAG = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Номер ЗД',
                value: payload.certificate_number,
                noMargin: true,
            },
            {
                name: 'Период поставки',
                value: moment(payload.last_delivery_period).format('MMMM, YYYY'),
            },
            {
                name: 'Общий объем по периоду, кВт*ч',
                value: String(currencyFormat(payload.volume)),
            },
        ],
    }
}

export const blockCertificateConfig = (
    eventDetails: AuditEventDetails<any>,
    dictionaries: any,
    isUnblock?: boolean,
) => {
    const { payload } = eventDetails
    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: 'Текст основания причины блокировки',
                value: payload.reason,
                noMargin: true,
            },
            isUnblock
                ? {
                      name: 'Был в залоге',
                      value: payload.is_deposit ? 'Да' : 'Нет',
                  }
                : {
                      name: 'Признак “Залог”',
                      value: payload.is_deposit ? 'Стоит' : 'Не стоит',
                  },
            {
                name: 'Номер ЛС, где находился СП',
                value: `№${payload.account_number}`,
            },
            {
                name: 'Номер СП',
                value: payload.certificate_number,
            },
            {
                name: 'Вид энергии',
                value: getEnergyType(payload.energy_type),
            },
            {
                name: 'Объем, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Название ГО',
                value: payload.generator_name,
            },
            {
                name: 'Период производства АГ',
                value: `${monthPicker(payload.month)} ${payload.year}`,
            },
            {
                name: 'Дата создания СП',
                value: moment(eventDetails.ts_event).format('DD.MM.YYYY'),
            },
            {
                name: 'Срок действия СП',
                value: `до ${moment(payload.expires_ts).format('DD.MM.YYYY')}`,
            },
        ],
    }
}

export const blockContractConfig = (eventDetails: AuditEventDetails<any>, dictionaries: any) => {
    const { payload } = eventDetails
    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: `Текст основания причины ${
                    eventDetails.type_event_id === 22 ? 'блокировки' : 'разблокировки'
                }`,
                value: payload.reason,
                noMargin: true,
            },
            {
                name: 'ЛС Покупателя',
                value: `№${payload.buyer.account_number}`,
            },
            {
                name: 'ЛС Продавца',
                value: `№${payload.seller.account_number}`,
            },
            {
                name: 'Номер ЗД',
                value: payload.contract_number,
                link: {
                    path: `/user-contract-card/${payload.contract_number}`,
                    state: {
                        cardLabel: 'Зеленый договор',
                        id: payload.contract_number,
                        isOwner: false,
                    },
                },
            },
            {
                name: 'Вид энергии',
                value: getEnergyType(payload.energy_type_id),
            },
            {
                name: 'Потребленный объем, кВт*ч',
                value: String(currencyFormat(payload.volume_consume)),
            },
            {
                name: 'Название Покупателя договора',
                value: payload.buyer.company_name_short,
            },
            {
                name: 'Коды ГТП покупателя',
                value: createCodes(payload.buyer.codes),
            },
            {
                name: 'Название Продавца договора',
                value: payload.seller?.company_name_short,
                link: {
                    path: `/owner/${payload.seller?.user_id}`,
                },
            },
            {
                name: 'Коды ГТП продавца',
                value: createCodes(payload.seller.codes),
            },
            {
                name: 'Дата создания ЗД',
                value: moment(payload.created_at).format('DD.MM.YYYY'),
            },
            {
                name: 'Период действия договора с',
                value: `с ${moment(payload.registered_at).format('DD.MM.YYYY')}`,
            },
        ],
    }
}

export const applicationPaymentConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'ID операции',
                value: payload.operation_id,
                noMargin: true,
            },
            {
                name: 'Тип платежной операции',
                value: 'Списание',
            },
            {
                name: 'Тип операции',
                value: 'Оплата по заявке',
            },
            {
                name: 'Сумма, ₽',
                value: currencyFormat(payload.service_fee),
            },
            {
                name: 'ID Заявки',
                value: payload.inquire_id,
            },
        ],
    }
}

export const autoPaymentConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Сумма пополнения, ₽',
                value: currencyFormat(payload.amount),
                noMargin: true,
            },
            {
                name: 'Дата зачисления',
                value: moment(payload.date).format('DD.MM.YYYY'),
            },
            {
                name: 'Номер счета',
                value: `№${payload.payment_acc_number}`,
            },
        ],
    }
}

export const AGRealisationConfig = (eventDetails: AuditEventDetails<AGRealisationEventDetails>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Номер ЗД',
                value: payload.contract_number,
                noMargin: true,
                link: {
                    path: `/user-contract-card/${payload.contract_number}`,
                    state: {
                        cardLabel: 'Зеленый договор',
                        id: payload.contract_number,
                        isOwner: false,
                    },
                },
            },
            {
                name: 'Период поставки',
                value: moment(payload.period_date).format('MMMM, YYYY'),
            },
            {
                name: 'Общий объем по периоду, кВт*ч',
                value: currencyFormat(payload.common_volume),
            },
        ],
    }
}

export const AGReservationConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Тип операции',
                value: payload.operation_type,
                noMargin: true,
            },
            {
                name: 'Объем АГ, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Сумма резервирования, ₽',
                value: currencyFormat(payload.operation_sum),
            },
            {
                name: 'Ссылка на ЗИ',
                value: payload.certificate_number,
                link: {
                    path: `/user-contract-card/${payload.certificate_id}`,
                    state: {
                        cardLabel: 'Зеленый договор',
                        id: payload.contract_number,
                        isOwner: false,
                    },
                },
            },
        ],
    }
}

export const AGCancelReservationConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Тип операции',
                value: payload.operation_type,
                noMargin: true,
            },
            {
                name: 'Ссылка на операцию',
                value: payload.reserv_operation_name,
                link: {
                    path: `/audit/history/${payload.reserv_operation_id}`,
                    state: {
                        eventData: {
                            eventType: payload.reserv_operation_name,
                            id: payload.reserv_operation_id,
                            eventId:
                                payload.reserv_operation_name ===
                                'Резервирование денежных средств для оплаты реализации АГ по ЗД'
                                    ? 58
                                    : 73,
                        },
                    },
                },
            },
        ],
    }
}

export const OthersAGConfig = (eventDetails: AuditEventDetails<any>, dictionaries: any) => {
    const { payload } = eventDetails
    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: 'Номер',
                value: payload.certificate_number,
                noMargin: true,
            },
            {
                name: 'Владелец',
                value: payload.owner_info
                    ? payload.owner_info.company_name_short.length
                        ? payload.owner_info.company_name_short
                        : payload.owner_info.full_name
                    : '',
            },
            {
                name: 'Вид энергии',
                value: getEnergyType(payload.energy_type),
            },
            {
                name: 'Объем, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Название ЗГО',
                value: payload.go_name,
            },
            {
                name: 'Период производства',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
            {
                name: 'Срок действия',
                value: moment(payload.expires_ts).format('DD.MM.YYYY'),
            },
        ],
    }
}

export const AGTransferConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails
    const totalVolume = payload.consumers
        ? payload.consumers.reduce((acc: number, consumer: IConsumer) => acc + consumer?.volume, 0)
        : payload.volume

    return {
        visibleInfo: [
            {
                name: 'Номер',
                value: payload.certificate_number,
                noMargin: true,
            },
            {
                name: 'Период погашения',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
            {
                name: 'Погашенный объем, кВт*ч',
                value: totalVolume,
            },
        ],
    }
}

export const OwnConsumingAGConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Название ЗГО',
                value: payload.generator_name,
                noMargin: true,
                link: {
                    path: `/generator-card/${payload.generator_id}`,
                },
            },
            {
                name: 'Период',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
            {
                name: 'Потребленный объем, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Свободный объем, кВт*ч',
                value: currencyFormat(payload.used_amount),
            },
            {
                name: 'Номер ЛС',
                value: `№ ${payload.account_number}`,
            },
        ],
    }
}

export const AGRemoveMark = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Название ЗГО',
                value: payload.generator_name,
                noMargin: true,
                link: {
                    path: `/generator-card/${payload.generator_id}`,
                },
            },
            {
                name: 'Объем, кВт*ч',
                value: currencyFormat(payload.unmarked_volume),
            },
            {
                name: 'Период производства',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
            {
                name: 'Номер  ЗД',
                value: payload.contract_number,
                link: {
                    path: `/user-contract-card/${payload.contract_number}`,
                    state: {
                        cardLabel: 'Зеленый договор',
                        id: payload.contract_number,
                        isOwner: false,
                    },
                },
            },
        ],
    }
}

export const OwnNeedsConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Название ЗГО',
                value: payload.generator_name,
                noMargin: true,
                link: {
                    path: `/generator-card/${payload.generator_id}`,
                },
            },
            {
                name: 'Общий объем, кВт*ч',
                value: currencyFormat(payload.total_volume),
            },
            {
                name: 'Объем собственных нужд, кВт*ч',
                value: currencyFormat(payload.self_redeemed_volume),
            },
            {
                name: 'Период производства',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
        ],
    }
}

export const switchAGConfig = (eventDetails: AuditEventDetails<any>, dictionaries: any) => {
    const { payload } = eventDetails
    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: 'Номер ЛС Продавца',
                value: `№${payload.seller?.account_id}`,
                noMargin: true,
            },
            {
                name: 'Номер ЛС Покупателя',
                value: `№${payload.buyer?.account_id}`,
            },
            {
                name: 'Номер  ЗД',
                value: payload.contract_number,
                link: {
                    path: `/user-contract-card/${payload.contract_number}`,
                    state: {
                        cardLabel: 'Зеленый договор',
                        id: payload.contract_number,
                        isOwner: false,
                    },
                },
            },
            {
                name: 'Объем добавленных в ЗД, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Вид энергии',
                value: getEnergyType(payload.energy_type_id),
            },
            {
                name: 'Реализованный объем, кВт*ч',
                value: currencyFormat(payload.volume_consume),
            },
            {
                name: 'Название Покупателя договора',
                value: payload.buyer?.company_name_short,
            },
            {
                name: 'Код ГТП покупателя',
                value: createCodes(payload.buyer?.codes),
            },
            {
                name: 'Название Продавца договора',
                value: payload.seller?.company_name_short,
                link: {
                    path: `/owner/${payload.seller?.user_id}`,
                },
            },
            {
                name: 'Код ГТП продавца (ЗГО)',
                value: createCodes(payload.seller?.codes),
            },
            {
                name: 'Дата создания ЗД',
                value: moment(payload.created_at).format('DD.MM.YYYY'),
            },
            {
                name: 'Период действия договора с',
                value: moment(payload.last_delivery_period).format('DD.MM.YYYY'),
            },
        ],
    }
}

export const fixationConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Название ЗГО',
                value: payload.generator_name,
                noMargin: true,
                link: {
                    path: `/generator-card/${payload.generator_id}`,
                },
            },
            {
                name: 'Объем АГ, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Период производства',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
        ],
    }
}

export const deregisterConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Название ЗГО',
                value: payload.generator_name,
                noMargin: true,
                link: {
                    path: `/generator-card/${payload.generator_id}`,
                },
            },
            {
                name: 'Дата снятия с учета',
                value: moment(payload.ts_deregister_add).format('DD.MM.YYYY'),
            },
        ],
    }
}

export const blockAGConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails

    return {
        visibleInfo: [
            {
                name: 'Причина блокировки',
                value: payload.reason,
                noMargin: true,
            },
            {
                name: 'Название ЗГО',
                value: payload.go_name,
                link: {
                    path: `/generator-card/${payload.generator_id}`,
                },
            },
            {
                name: 'Объем АГ, кВт*ч',
                value: currencyFormat(payload.volume),
            },
            {
                name: 'Период производства',
                value: `${monthPicker(payload.month)}, ${payload.year}`,
            },
        ],
    }
}

export const AGToThirdConfig = (eventDetails: AuditEventDetails<any>) => {
    const { payload } = eventDetails
    const periodArray = payload?.period?.split('-')

    return {
        visibleInfo: [
            {
                name: 'Номер СП',
                value: payload.certificate_number,
                noMargin: true,
                link: {
                    path: `/user-certificate-card/${payload.certificate_number}`,
                    state: {
                        toolType: 'Сертификаты происхождения',
                        cardLabel: 'Сертификат происхождения',
                        id: payload.certificate_id,
                        isOwner: false,
                    },
                },
            },
            {
                name: 'Период погашения',
                value: `${monthPicker(Number(periodArray[1]))}, ${periodArray[0]}`,
            },
            {
                name: 'Погашенный объем, кВт*ч',
                value: currencyFormat(payload.cert_total_volume),
            },
            {
                name: 'Общий погашенный объем в третьи лица, кВт*ч',
                value: currencyFormat(payload.cert_third_person_volume),
            },
        ],
    }
}

export const changeContractNumber = (eventDetails: AuditEventDetails<any>, dictionaries: any) => {
    const { payload } = eventDetails
    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    return {
        visibleInfo: [
            {
                name: 'Номер ЛС, где находился ЗД',
                value: `№${payload.account_number}`,
                noMargin: true,
            },
            {
                name: 'Номер нового ЛС',
                value: `№${payload.account_new}`,
            },
            {
                isDivider: true,
                noMargin: true,
            },
            {
                name: 'Номер ЗД',
                value: payload.contract_number,
                link: {
                    path: `/user-contract-card/${payload.contract_number}`,
                    state: {
                        cardLabel: 'Зеленый договор',
                        id: payload.contract_number,
                        isOwner: false,
                    },
                },
                noMargin: true,
            },
            {
                name: 'Вид энергии',
                value: getEnergyType(payload.renewable_energy_type_id),
            },
            {
                name: 'Название Продавца договора',
                value: payload.Seller?.company_name_short,
                link: {
                    path: `/owner/${payload.Seller?.user_id}`,
                },
            },
            {
                name: 'Коды ГТП покупателя',
                value: createCodes(payload.Buyer?.codes),
            },
            {
                name: 'Коды ГТП продавца',
                value: createCodes(payload.Seller?.codes),
            },
            {
                name: 'Период поставки',
                value: moment(payload.ts_delivery).format('MMMM, yyyy'),
            },
            {
                name: 'Дата создания ЗД',
                value: moment(payload.ts_created).format('DD.MM.YYYY'),
            },
        ],
    }
}
