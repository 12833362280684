import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, Box } from '../../../ui'
import { Checkbox } from 'antd'
import { ButtonsBlock } from '../../../Edit/components'
import { useGetTariffRate } from '../../../hooks'
import { currencyFormat } from '../../../helpers'
import { fetchApprove } from '../../config'
import useRequest from '../../../hooks/useRequest'

import style from './style.m.less'

interface IApproveModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    setActionConfirmed: (value: boolean) => void   
    data: any
    total_cost_with_tax: string
}

const ApproveModal: FC<IApproveModal> = ({ isModalVisible, setIsModalVisible, setActionConfirmed, data, total_cost_with_tax }) => {
    const navigate = useNavigate()
    const [checked, setIsChecked] = useState(false)
    const { fetch } = useRequest(fetchApprove)

    const { count, volume, ts_created } = data

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const { balance, rate, rateNumber } = useGetTariffRate({
        dependency: isModalVisible,
        id: 79,
        volume,
    })

    const getBack = () => {
        setIsModalVisible(false)
        setActionConfirmed(true)
        fetch({
            body: {
                fee: rateNumber,
                ts_created,
            },
            successMessage: {
                title: 'Успешно',
                description: `Данные сторонних инструментов успешно согласованы`,
            },
            errorCodeMessage: true,
            onSuccess: () => navigate('/user-my-green-tools'),
        })
    }

    const onChange = (e: any) => {
        setIsChecked(e.target.checked)
    }

    return (
        <Modal isModalVisible={isModalVisible} bodyStyle={{ width: '600px' }}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <div className={style.header}>Согласование данных</div>
                </div>
            </div>
            <div className={style.infoWrapper}>
                <Box direction="row">
                    <div className={style.greyBlock}>Количество инструментов</div>
                    <div>{count}</div>
                </Box>
                <Box direction="row">
                    <div className={style.greyBlock}>Общий объем</div>
                    <div>{`${currencyFormat(volume)} кВт*ч`}</div>
                </Box>
            </div>
            <div className={style.payAlert}>
                <div className={style.payHeader}>
                    <span className={style.alertMessage}>Сумма комиссии составила </span>
                    <span className={style.alertPrice}>{total_cost_with_tax}</span>
                </div>
                <div className={style.payText}>
                    <span className={style.alertDetail}>Баланс платежного счета:</span>
                    <span className={style.alertDetail}> {balance}</span>
                </div>
            </div>
            <Checkbox checked={checked} className={style.checkbox} onClick={onChange}>
                {`Подтверждаю данные по сторонним инструментам`}
            </Checkbox>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={getBack}
                    cancelAction={handleOk}
                    disableCondition={!checked}
                    confirmTitle="Подтвердить и оплатить"
                />
            </div>
        </Modal>
    )
}

export default ApproveModal
